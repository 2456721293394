// Angular
import { Injectable } from '@angular/core';

// Custom
import { environment } from 'src/environments/environment';
import { FormioForm } from '@formio/angular';
import { ItemWorkflowFile } from '../interfaces/items/item-workflow-file';
import { ItemWorkflowFileType } from '../enum/item/item-workflow-file-type.enum';
import { ItemWorkflowFilePrefixes } from '../enum/item/item-workflow-file-prefixes.enum';

@Injectable({
	providedIn: 'root'
})
export class UtilsService {

	private readonly headers: { key: string, value: string }[] = [
		{ key: "Content-Type", value: "application/json" },
		{ key: "Authorization", value: "Bearer @@userToken@@" },
		{ key: "contract-id", value: "@@contratoActual@@" },
		{ key: "schema-id", value: "@@currentSchema@@" },
		{ key: "workspace-id", value: "@@currentWorkspace@@" }
	];

	constructor(
	) { }

	async asyncForEach(array: any, callback: any) {
		for (let index = 0; index < array.length; index++) {
			await callback(array[index], index, array);
		}
	}

	/**
	 * Summary. Replace custom variables of the schema based on regular expression
	 * 
	 * @search @@some_text@@
	 * 
	 * @param {string} schema formio schema to be parsed
	 * 
	 * @returns {object} JSON object with parsed schema
	 */
	public async customSchemaChanges(schema: string): Promise<FormioForm> {
		const headerString = JSON.stringify(this.headers);
		const expression = /(?<headerKey>"headers"\: )\[(?<headerValues>[^\]]*)\]/sg;
		schema = schema.toString().replace(expression, `"headers": [${headerString.slice(1, headerString.length - 1)},$<headerValues>]`);

		let search = "@@apiUrl@@";
		let searchRegExpr = new RegExp(search, 'g');
		schema = schema.replace(searchRegExpr, environment.apiUrlFormio);

		search = ",\n                            \"ignoreCache\": true\n";
		searchRegExpr = new RegExp(search, 'g');
		schema = schema.replace(searchRegExpr, "");

		search = ",\n                            \"ignoreCache\": false\n";
		searchRegExpr = new RegExp(search, 'g');
		schema = schema.replace(searchRegExpr, "");

		search = "\"selectValues\": \"entities\",";
		searchRegExpr = new RegExp(search, 'g');
		schema = schema.replace(searchRegExpr, "\"selectValues\": \"entities\",\n                            \"ignoreCache\": true,\n");

		return JSON.parse(schema);
	}

	/**
	 * Parses file info to a link
	 * @param file file information
	 * @returns url to the file
	 */
	public getFileUrl(file: ItemWorkflowFile): string {
		switch (file.type) {
			case ItemWorkflowFileType.GDOC:
				return `${ItemWorkflowFilePrefixes.GDOC}${file.documentId}`;
			default:
				return ItemWorkflowFilePrefixes.DEFAULT;
		}
	}
}
