import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import {
	NbThemeModule,
	NbLayoutModule,
	NbToastrModule,
	NbDialogModule,
	NbCardModule,
	NbIconModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { FormioModule } from '@formio/angular';

import * as Formio from 'formiojs';
import { FormComponentComponent } from './form-component/form-component.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CompletedComponent } from './completed/completed.component';
import { ErrorComponent } from './error/error.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha'
import { environment } from '../environments/environment';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
	declarations: [
		AppComponent,
		FormComponentComponent,
		NotFoundComponent,
		CompletedComponent,
		ErrorComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		NoopAnimationsModule,
		NbThemeModule.forRoot({ name: 'default' }),
		NbLayoutModule,
		NbEvaIconsModule,
		NbCardModule,
		NbIconModule,
		FormioModule,
		NbToastrModule.forRoot(),
		NbDialogModule.forRoot(),
		RecaptchaV3Module,
		NgxSpinnerModule
	],
	providers: [
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.recaptcha.siteKey,
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
