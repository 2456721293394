import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ConfigService } from '../core/shared/config.service';
import { ToastService } from '../core/shared/toast.service';
import { environment } from '../../environments/environment';

@Component({
	selector: 'app-completed',
	templateUrl: './completed.component.html',
	styleUrls: ['./completed.component.scss']
})
export class CompletedComponent implements OnInit {

	private readonly SUBSCRIPTIONS: Subscription = new Subscription();

	private formID: any = '';

	public loading: boolean = true;

	public messages: any = {};

	public logo: string = '';

	constructor(private route: ActivatedRoute,
		private configService: ConfigService,
		private spinner: NgxSpinnerService,
		private toastService: ToastService,
		private router: Router) { }

	ngOnInit(): void {
		this.SUBSCRIPTIONS.add(this.route.paramMap.subscribe(async (params: ParamMap) => {
			this.loading = true;

			this.spinner.show();

			this.formID = params.get('formID');

			try {
				if (!!this.formID && this.formID !== '') {
					const config: any = await this.configService.getMessages(this.formID, 'completed');

					if (config.data != null) {
						this.messages = config.data;
						this.logo = `${environment.imagesUrl}${this.messages.FormLogo}`
					} else {
						await this.router.navigateByUrl(`not-found`);
					}
				} else {
					await this.router.navigateByUrl(`not-found`);
				}
			} catch (error: any) {
				this.toastService.danger('there was an error loading data');
				console.error('Error loading data: ', error);
			} finally {
				this.spinner.hide();
				this.loading = false;
			}
		}));
	}

}
