import { Injectable } from '@angular/core';
import * as Formio from 'formiojs';
import { SchemaValidationParsed } from '../interfaces/schema/schema-validation-parsed';
import { SchemaValidationResult } from '../interfaces/schema/schema-validation-result';
import { LanguageService } from './language.service';

export { SchemaValidationParsed } from '../interfaces/schema/schema-validation-parsed';
export { SchemaValidationResult } from '../interfaces/schema/schema-validation-result';

export { SchemaValidation } from '../interfaces/schema/schema-validation';
export { SchemaValidationsDefinition } from '../interfaces/schema/schema-validations-definition';

@Injectable({
	providedIn: 'root'
})
export class SchemaValidationService {

	constructor(
		private language: LanguageService
	) { }

	/**
	 * Get display names of invalid componentes
	 * @param result validation result
	 * @param config Schema config
	 * @returns displaynames that doesn't satisfy validation
	 */
	public getDisplayNames(result: SchemaValidationResult, config: any): SchemaValidationParsed[] {
		let names: SchemaValidationParsed[] = [];
		Formio.Utils.eachComponent(config.components, (component: any) => {
			if (Object.keys(result).includes(component.key)) {
				const validationResult: { result: boolean, message: string } | SchemaValidationResult = result[component.key];
				if (!validationResult.result)
					names.push({ label: component.label, message: (validationResult.message ?? this.language.translateText('invalid value')) as string })
			}
		});

		return names;
	}

}
