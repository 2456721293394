import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class FormioCustomService {

	private subject = new Subject<any>();
	private submissionData: any;

	constructor() { }

	/**
	 * Sets the next data of subject to the message
	 * @param message message to submit
	 */
	public sendInfo(message: string): void {
		this.subject.next({ data: message });
	}

	/**
	 * Gets an observable of the subject item
	 * @returns the observable
	 */
	public onInfo(): Observable<any> {
		return this.subject.asObservable();
	}

	/**
	 * Sets the submission data of the item
	 */
	set SubmissionData(data) {
		this.submissionData = data;
	}

	/**
	 * Gets the submissionData of the item
	 */
	get SubmissionData() {
		return this.submissionData
	}
}
