import { Injectable } from '@angular/core';
import { NbToastrService, NbGlobalPhysicalPosition, NbToastrConfig } from '@nebular/theme';
import { LanguageService } from './language.service';

@Injectable({
	providedIn: 'root'
})
export class ToastService {

	private readonly POSITION: NbGlobalPhysicalPosition = NbGlobalPhysicalPosition.BOTTOM_LEFT
	private readonly DURATION: number = 10000;

	private readonly TOAST_CONFIG: Partial<NbToastrConfig> = {
		"position": this.POSITION,
		"duration": this.DURATION,
	};

	private INFO_TITLE: string = this.languageService.translateText("Information");
	private readonly SUCCESS_TITLE: string = "Completado";
	private readonly WARNING_TITLE: string = "Atención";
	private readonly DANGER_TITLE: string = "Error";

	constructor(private toastrService: NbToastrService,
		private languageService: LanguageService) { }

	public info(message: string, errorMessage?: string): void {
		let fullText = errorMessage ?
			`${this.languageService.translateText(message)} - ${errorMessage}` : this.languageService.translateText(message);

		this.toastrService.info(
			fullText,
			this.languageService.translateText(this.INFO_TITLE),
			this.TOAST_CONFIG);
	}

	public success(message: string, errorMessage?: string): void {
		let fullText = errorMessage ?
			`${this.languageService.translateText(message)} - ${errorMessage}` : this.languageService.translateText(message);

		this.toastrService.success(
			fullText,
			this.languageService.translateText(this.SUCCESS_TITLE),
			this.TOAST_CONFIG);
	}

	public warning(message: string, errorMessage?: string): void {
		let fullText = errorMessage ?
			`${this.languageService.translateText(message)} - ${errorMessage}` : this.languageService.translateText(message);

		this.toastrService.warning(
			fullText,
			this.languageService.translateText(this.WARNING_TITLE),
			this.TOAST_CONFIG);
	}

	public danger(message: string, errorMessage?: string): void {
		let fullText = errorMessage ?
			`${this.languageService.translateText(message)} - ${errorMessage}` : this.languageService.translateText(message);

		this.toastrService.danger(
			fullText,
			this.languageService.translateText(this.DANGER_TITLE),
			this.TOAST_CONFIG);
	}
}
