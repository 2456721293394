import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompletedComponent } from './completed/completed.component';
import { ErrorComponent } from './error/error.component';
import { FormComponentComponent } from './form-component/form-component.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
	{
		'path': 'completed/:formID',
		'component': CompletedComponent
	},
	{
		'path': 'error/:formID',
		'component': ErrorComponent
	},
	{
		'path': 'not-found',
		'component': NotFoundComponent
	},
	{
		'path': ':formID',
		'component': FormComponentComponent
	},
	{ path: '**', redirectTo: ':formId', },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }

