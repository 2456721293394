import { Component, EventEmitter, OnInit } from '@angular/core';
import { ConfigService } from '../core/shared/config.service';

import { FormioSubmission } from '@formio/angular';
import { FormioCustomService } from '../core/shared/formio-custom.service';

import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ItemData } from 'src/app/core/interfaces/items/item-data';
import { ItemSystem } from "src/app/core/interfaces/items/item-system";
import { ToastService } from 'src/app/core/shared/toast.service';
import { ItemsService } from "src/app/core/shared/items.service";
import { SchemaValidationParsed, SchemaValidationResult, SchemaValidationService } from "src/app/core/shared/schema-validation.service";

import * as Formio from 'formiojs';
import { ItemParamsService } from '../core/shared/item-params.service';
import { UtilsService } from '../core/shared/utils.service';
import { environment } from '../../environments/environment';

@Component({
	selector: 'app-form-component',
	templateUrl: './form-component.component.html',
	styleUrls: ['./form-component.component.scss']
})
export class FormComponentComponent implements OnInit {

	private readonly CUSTOM_EVENT_TYPE: string = 'customEvent';
	private readonly SUBSCRIPTIONS: Subscription = new Subscription();

	private readonly WS_COLOR: string = '--ws-color';
	private readonly WS_FONT: string = '--ws-font';
	private readonly DEFAULT_COLOR: string = '#61bd4f';
	private readonly PCTCAN_COLOR: string = '#4BB174';
	private readonly PCTCAN_FONT: string = 'dinNext_medium';
	private readonly PCTCAN: string = 'pctcan';

	private configuration: any;
	private user: string = '';
	private formID: any = '';

	private formQuery: any;
	private formType: number = 0;

	public logo: string = '';

	public formularioConfig: any;
	public formularioSubmission: FormioSubmission = { data: {} };
	public refreshForm = new EventEmitter();

	public loading: boolean = true;

	constructor(private configService: ConfigService,
		private formioService: FormioCustomService,
		private spinner: NgxSpinnerService,
		private route: ActivatedRoute,
		private router: Router,
		private itemService: ItemsService,
		private toastService: ToastService,
		private schemaValidation: SchemaValidationService,
		private itemParamsService: ItemParamsService,
		private utilService: UtilsService) { }

	async ngOnInit(): Promise<void> {
		this.subscribeQueryParams();

		await this.subscribeParams();
	}

	/**
	 * Sets color and font (PCTCAN) when rendering is completed
	 * @param event rendering completion event
	 */
	public rendered(event: any): void {
		const formSelector: any = document.getElementById('formio');

		if (!!this.configuration.WSThemes) {
			if (!!this.configuration.WSThemes.primary)
				formSelector.style.setProperty(this.WS_COLOR, this.configuration.WSThemes.primary.toString())

			if (!!this.configuration.WSThemes.fontFamily)
				formSelector.style.setProperty(this.WS_FONT, this.configuration.WSThemes.fontFamily.toString())
		}
	}

	/**
	 * Shows the logo only if is loaded
	 */
	public showImg(): void {
		const [logoImg]: any = document.getElementsByClassName('logo');

		logoImg.style.display = 'block';
	}

	/**
	 * Subscribe to route params
	 */
	private async subscribeParams(): Promise<void> {
		this.SUBSCRIPTIONS.add(this.route.paramMap.subscribe(async (params: ParamMap) => {

			this.loading = true;

			this.spinner.show();

			this.formID = params.get('formID');

			let cookie = document.cookie;

			if (cookie === 'sent=true')
				await this.router.navigateByUrl(`completed/${this.formID}`,);

			try {
				if (!!this.formID && this.formID !== '') {
					const config: any = await this.configService.getConfig(this.formID);

					if (config.data != null) {
						this.configuration = config.data;
						this.logo = `${environment.imagesUrl}${this.configuration.FormLogo}`
						this.formType = Number(this.configuration.FormID);
						this.formularioConfig = await this.utilService.customSchemaChanges(config.data.FormSchema);
						this.user = this.configuration.WSGenericUser;

						Formio.Utils.eachComponent(this.formularioConfig.components, (component: any) => {
							component.attributes = {}
						});

						this.refreshForm.emit({ form: this.formularioConfig });
					} else {
						await this.router.navigateByUrl(`not-found`);
					}
				} else {
					await this.router.navigateByUrl(`not-found`);
				}

			} catch (error: any) {
				this.toastService.danger('there was an error loading data');
				console.error('Error loading data: ', error);

				await this.router.navigateByUrl(`not-found`);
			} finally {
				this.spinner.hide();
				this.loading = false;
			}
		}));
	}

	ngOnDestroy(): void {
		this.SUBSCRIPTIONS.unsubscribe();
	}

	/**
	 * Saves form response on database and redirects to preview
	 * @param submission Form response
	 */
	public async onSubmit(submission: FormioSubmission): Promise<void> {
		await this.spinner.show();

		const itemData: ItemData = {} as ItemData;

		submission.data.userCreated = this.user;

		itemData.Records = submission.data;
		itemData.Workflow = [];
		itemData.System = {
			CreatedBy: this.user,
			CreatedDate: new Date(),
			ModifiedBy: this.user,
			ModifiedDate: new Date(),
			AssignedTo: "",
			AssignedDate: "",
			CreatedFrom: "WEB",
			ModifiedFrom: "WEB",
			Files: []
		} as unknown as ItemSystem;

		try {
			const result: any = await this.itemService.createItem(this.formType, this.configuration.Contract_ID, itemData, this.configuration.WSID);

			let date = new Date();
			date.setDate(date.getDate() + 1);
			date.setHours(0, 0, 0);

			document.cookie = `sent=true; expires=${date}`;

			if (result != undefined && result.message === 'OK')
				this.toastService.success('item created');
			await this.router.navigateByUrl(`completed/${this.formID}`);

			// await this.goToPreview(newItemId);
		} catch (error: any) {
			this.toastService.warning(`item not created`, error?.error?.message ?? error.status);

			this.refreshForm.emit({ form: this.formularioConfig });

			await this.router.navigateByUrl(`error/${this.formID}`);
		} finally {
			await this.spinner.hide();
		}

	}

	public customEvent(event: any): void {
		if (event.type === this.CUSTOM_EVENT_TYPE)
			this.formioService.sendInfo(event.data);
	}

	/**
	 * Manage change to apply format rules
	 * @param event change detail
	 */
	public change(event: any): void {
		if (event.changed != null) {
			const cached = JSON.parse(JSON.stringify(this.formularioConfig));
		}
	}

	/**
	 * Subscribe to route query params
	 */
	private subscribeQueryParams(): void {
		this.SUBSCRIPTIONS.add(this.route.queryParamMap.subscribe(async (queryParams: ParamMap) => {
			this.formQuery = queryParams;
		}));
	}

	/**
	 * Get display names of invalid componentes
	 * @param validation Validation result
	 * @returns displaynames that doesn't satisfy validation
	 */
	private getInvalidDisplaynames(validation: SchemaValidationResult): SchemaValidationParsed[] {
		const keys = Object.keys(validation);
		for (let k of keys) {
			if (k.includes('.')) {
				validation[k.split('.')[0]] = validation[k];
				delete validation[k];
			}
		}
		return this.schemaValidation.getDisplayNames(validation, this.formularioConfig);
	}
}

export class formIO {
	display = "form";
	components = [];
}

