import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
	selector: 'ngx-app',
	template: '<router-outlet></router-outlet>',
})
export class AppComponent {

	constructor(private recaptchaV3Service: ReCaptchaV3Service) { }

	/**
	 * Sends the recaptcha form info to validate the web
	 * @param form the recaptcha form
	 * @returns void
	 */
	public send(form: NgForm): void {
		if (form.invalid) {
			for (const control of Object.keys(form.controls))
				form.controls[control].markAsTouched();

			return;
		}

		this.recaptchaV3Service.execute('importantAction')
			.subscribe((token: string) => { });
	}
}