interface Translation {
	[key: string]: {
		es: string,
		en: string
	}
};

export const texts: Translation = {
	'home': {
		es: 'Inicio',
		en: 'Home'
	},
	'users': {
		es: 'Usuarios',
		en: 'Users'
	},
	'invitations': {
		es: 'Invitaciones',
		en: 'Invitations'
	},
	'contracts': {
		es: 'Contratos',
		en: 'Contracts'
	},
	'contract': {
		es: 'Contrato',
		en: 'Contract'
	},
	'area': {
		es: 'Area',
		en: 'Area'
	},
	'areas': {
		es: 'Areas',
		en: 'Areas'
	},
	'workplaces': {
		es: 'Centros de trabajo',
		en: 'Workplaces'
	},
	'reports': {
		es: 'Reportes',
		en: 'Reports'
	},
	'csv import': {
		es: 'Importar CSV',
		en: 'CSV import'
	},
	'audit log': {
		es: 'Auditoría',
		en: 'Audit log'
	},
	'realtime': {
		es: 'Tiempo real',
		en: 'Realtime'
	},
	'checking': {
		es: 'Fichaje',
		en: 'Checking'
	},
	'settings': {
		es: 'Configuración',
		en: 'Settings'
	},
	"vendors": {
		es: "PROVEEDORES",
		en: "Vendors"
	},
	"requests for proposal": {
		es: "Solicitudes de Oferta",
		en: "Requets for proporsal"
	},
	"orders": {
		es: "Pedidos",
		en: "Orders"
	},
	"incidents": {
		es: "Incidencias",
		en: "Incidents"
	},
	"quality": {
		es: "Calidad",
		en: "Quality"
	},
	"account": {
		es: "Cuenta",
		en: "Account"
	},
	"profile": {
		es: "Perfil",
		en: "Profile"
	},
	"auth": {
		es: "Autenticación",
		en: "Authentication"
	},
	"log in": {
		es: "Inicio de Sesión",
		en: "Log in"
	},
	"log out": {
		es: "Cerrar Sesión",
		en: "Log out"
	},
	"request password": {
		es: "Pedir Contraseña",
		en: "Request password"
	},
	"reset password": {
		es: "Restablecer Contraseña",
		en: "Reset password"
	},
	"expired": {
		es: "Expirado",
		en: "Expired"
	},
	"minutes": {
		es: "minutos",
		en: "minutes"
	},
	"hours": {
		es: "horas",
		en: "hours"
	},
	"messaging": {
		es: "Mensajería",
		en: "Messaging"
	},
	"log": {
		es: "Log",
		en: "Log"
	},
	"information": {
		es: "Información",
		en: "Information"
	},
	"completed": {
		es: "Completado",
		en: "Completed"
	},
	"atention": {
		es: "Atención",
		en: "Attention"
	},
	"historical demerit point ratio\nper material per orden line": {
		es: "RATIO HISTÓRICO DE PUNTOS DE DEMÉRITO\nPOR MATERIAL POR LÍNEAS DE PEDIDO",
		en: "Historical demerit point ratio\nper material per orden line"
	},
	"historical demerit point ratio\nfor material by purchase volume": {
		es: "RATIO HISTÓRICO DE PUNTOS DE DEMÉRITO\nPOR MATERIAL POR VOLUMEN DE COMPRAS",
		en: "Historical demerit point ratio\nfor material by purchase volume"
	},
	"demerit point history\n(material type)": {
		es: "HISTÓRICO DE PUNTOS DE DEMÉRITO\n(TIPO MATERIAL)",
		en: "Demerit point history\n(material type)"
	},
	"demerit point history\n(type of delivery time)": {
		es: "HISTÓRICO DE PUNTOS DE DEMÉRITO\n(TIPO PLAZO DE ENTREGA)",
		en: "Demerit point history\n(type of delivery time)"
	},
	"demerit point history\n(administrative type)": {
		es: "HISTÓRICO DE PUNTOS DE DEMÉRITO\n(TIPO ADMINISTRATIVA)",
		en: "Demerit point history\n(administrative type)"
	},
	"claim history": {
		es: "HISTÓRICO DE NÚMERO DE RECLAMACIONES",
		en: "Claim history"
	},
	"active": {
		es: "Activa",
		en: "Active"
	},
	"done": {
		es: "Completada",
		en: "Done"
	},
	"cancelled": {
		es: "Cancelada",
		en: "Cancelled"
	},
	"couldn't load user info": {
		es: "La información del usuario no se ha podido cargar correctamente.",
		en: "Couln't load user info."
	},
	"area not found": {
		es: "Área no encontrada.",
		en: "Area not found."
	},
	"area updated": {
		es: "Área actualizada correctamente.",
		en: "Area correctly updated."
	},
	"area not updated": {
		es: "El área no se ha actualizado correctamente.",
		en: "Couldn't update area."
	},
	"area created": {
		es: "Área creada correctamente.",
		en: "Area correctly created."
	},
	"area not created": {
		es: "El área no se ha creado correctamente.",
		en: "Couldn't create area."
	},
	"monitoring area deleted": {
		es: "El área de monitorización se ha eliminado correctamente.",
		en: "Monitoring area deleted."
	},
	"monitoring area not deleted": {
		es: "El área de monitorización no se ha eliminado correctamente.",
		en: "Couldn't delete monitoring area."
	},
	"monitoring area created": {
		es: "El área de monitorización se ha creado correctamente.",
		en: "Monitoring area created."
	},
	"monitoring area not created": {
		es: "El área de monitorización no se ha creado correctamente.",
		en: "Couldn't create monitoring area."
	},
	"invitation has been used, cannot resend": {
		es: "La invitación ya ha sido usada, no se puede reenviar.",
		en: "Invitation has been used, cannot resend."
	},
	"invitation doesn't exists": {
		es: "La invitación no existe.",
		en: "Invitation doesn't exists."
	},
	"could not resend invitation": {
		es: "No se pudo reenviar la invitación.",
		en: "Could not resend invitation."
	},
	"could not update invitation": {
		es: "No se pudo actualizar la invitación.",
		en: "Could not update invitation."
	},
	"invitation updated": {
		es: "La invitación se actualizó correctamente.",
		en: "Invitation correctly updated."
	},
	"invitation has been resent": {
		es: "La invitación ha sido reenviada.",
		en: "Invitation has been resent."
	},
	"cannot edit used invitation": {
		es: "No se puede editar una invitación usada.",
		en: "Cannot edit an used invitation."
	},
	"name": {
		es: "Nombre",
		en: "Name"
	},
	"description": {
		es: "Descripción",
		en: "Description"
	},
	"event type": {
		es: "Tipo evento",
		en: "Event type"
	},
	"scope": {
		es: "Ámbito",
		en: "Scope"
	},
	"contract name": {
		es: "Nombre del contrato",
		en: "Contract name"
	},
	"date": {
		es: "Fecha",
		en: "Date"
	},
	"surname": {
		es: "Primer Apellido",
		en: "Surname"
	},
	"last name": {
		es: "Segundo Apellido",
		en: "Last name"
	},
	"email": {
		es: "Email",
		en: "Email"
	},
	"role": {
		es: "Rol",
		en: "Role"
	},
	"document number": {
		es: "N. Expediente",
		en: "Document number"
	},
	"working hours": {
		es: "Horas Trabajo",
		en: "Working hours"
	},
	"anual working hours": {
		es: "Horas Trabajo Anual",
		en: "Anual working hours"
	},
	"daily working hours": {
		es: "Horas Trabajo Diario",
		en: "Daily working hours"
	},
	"resting minutes": {
		es: "Min. Descanso",
		en: "Resting minutes"
	},
	"main contract": {
		es: "Contrato principal",
		en: "Main contract"
	},
	"status": {
		es: "Estado",
		en: "Status"
	},
	"user": {
		es: "Usuario",
		en: "User"
	},
	"resource id": {
		es: "Id del recurso",
		en: "resouce id"
	},
	"detail": {
		es: "Detalle",
		en: "Detail"
	},
	"couldn't load audit logs": {
		es: "No ha sido posible cargar los registros de auditoría.",
		en: "Couln't load audit logs."
	},
	"pending": {
		es: "Pendiente",
		en: "Pending"
	},
	"success": {
		es: "Satisfactorio",
		en: "Sucess"
	},
	"successs": {
		es: "Satisfactorio",
		en: "Sucess"
	},
	"failed": {
		es: "Fallido",
		en: "Failed"
	},
	"contract saved": {
		es: "Contrato guardado correctamente.",
		en: "Contract correctly saved."
	},
	"contract not saved": {
		es: "El contrato no se ha guardado correctamente.",
		en: "Couldn't save contract."
	},
	"contracts couldn't be loaded": {
		es: "La información de los contratos no se ha cargado correctamente.",
		en: "Contracts couldn't be loaded."
	},
	"no data found": {
		es: "No se han encontrado registros.",
		en: "No data found."
	},
	"-all-": {
		es: "-Todos-",
		en: "-All-"
	},
	"contract code": {
		es: "Código de contrato",
		en: "Contract code"
	},
	"street": {
		es: "Calle",
		en: "Street"
	},
	"number": {
		es: "Número",
		en: "Number"
	},
	"p.c.": {
		es: "C.P.",
		en: "P.C."
	},
	"town": {
		es: "Localidad",
		en: "Town"
	},
	"modules": {
		es: "Módulos",
		en: "modules"
	},
	"csv upload": {
		es: "Subida de datos mediante CSV",
		en: "CSV data upload"
	},
	"send": {
		es: "Enviar",
		en: "Send"
	},
	"list": {
		es: "Listar",
		en: "List"
	},
	"created by": {
		es: "Creador",
		en: "Created by"
	},
	"imported csv": {
		es: "CSV importado",
		en: "Imported CSV"
	},
	"csv result": {
		es: "CSV resultado",
		en: "CSV result"
	},
	"finished": {
		es: "Finalizado",
		en: "Finished"
	},
	"processing": {
		es: "En proceso",
		en: "Processing"
	},
	'there was an error loading data': {
		es: "Se ha producido un error al cargar los datos.",
		en: "There was an error loading data."
	},
	'item created': {
		es: "El registro se ha creado correctamente.",
		en: "Item created."
	},
	'item not created': {
		es: "El registro no ha sido creado correctamente.",
		en: "Item couldn't be created."
	},
	'item updated': {
		es: "El registro se ha actualizado correctamente.",
		en: "Item updated."
	},
	'item not updated': {
		es: "El registro no ha sido actualizado correctamente.",
		en: "Item couldn't be updated."
	},
	'item deleted': {
		es: "El registro se ha eliminado correctamente.",
		en: "Item deleted."
	},
	'item not deleted': {
		es: "El registro no ha sido eliminado correctamente.",
		en: "Item couldn't be deleted."
	},
	'item disabled': {
		es: "El registro se ha deshabilitado correctamente.",
		en: "Item disabled."
	},
	'item not disabled': {
		es: "El registro no ha sido deshabilitado correctamente.",
		en: "Item couldn't be disabled."
	},
	'item not found': {
		es: "No se ha encontrado el registro.",
		en: "Item not found."
	},
	'cannot edit a disabled item': {
		es: "No se puede editar un recurso deshabilitado.",
		en: "Cannot edit a disabled item."
	},
	'markers': {
		es: "Marcadores",
		en: "Markers"
	},
	'start': {
		es: "Inicio",
		en: "Start"
	},
	'end': {
		es: "Fin",
		en: "End"
	},
	'warehouse': {
		es: "Almacén",
		en: "Warehouse"
	},
	'others': {
		es: "Otros",
		en: "Others"
	},
	'new location': {
		es: "Nueva ubicación",
		en: "New location"
	},
	'location created': {
		es: "Ubicación creada correctamente.",
		en: "Location created."
	},
	'location couldn\'t be created': {
		es: "La ubicación no se ha creado correctamente.",
		en: "Location couldn't be created."
	},
	'location deleted': {
		es: "Ubicación eliminada correctamente.",
		en: "Location deleted."
	},
	'location couldn\'t be deleted': {
		es: "La ubicación no se ha eliminado correctamente.",
		en: "Location couldn't be deleted."
	},
	'location updated': {
		es: "Ubicación actualizada correctamente.",
		en: "Location updated."
	},
	'location couldn\'t be updated': {
		es: "La ubicación no se ha actualizada correctamente.",
		en: "Location couldn't be updated."
	},
	'location couldn\'t be found': {
		es: "La ubicación no se ha encontrado.",
		en: "Location couldn't be found."
	},
	'identifier': {
		es: "Identificador",
		en: "Identifier"
	},
	'floor': {
		es: "Planta",
		en: "Floor"
	},
	'space': {
		es: "Espacio",
		en: "Space"
	},
	'use': {
		es: "Uso",
		en: "Use"
	},
	'historic': {
		es: "Histórico",
		en: "Historic"
	},
	'historic reports': {
		es: "Histórico de reportes",
		en: "Historic reports"
	},
	'create and send': {
		es: "Crear y enviar",
		en: "Create and send"
	},
	'create and send report': {
		es: "Crear y enviar reporte",
		en: "Create and send report"
	},
	'periodicity': {
		es: "Periodicidad",
		en: "Periodicity"
	},
	'report': {
		es: "Reporte",
		en: "Report"
	},
	'type': {
		es: "Tipo",
		en: "Type"
	},
	'email and password': {
		es: "Email y contraseña",
		en: "Email and password"
	},
	'bussines microsoft account': {
		es: "Cuenta corporativa de Microsoft",
		en: "Bussines microsoft account"
	},
	'modules disabled by contract': {
		es: "Módulos deshabilitados por contrato",
		en: "Modules disabled by contract"
	},
	'user contract dependency correctly deleted': {
		es: "Se ha eliminado correctamente la dependencia de este usuario con el contrato.",
		en: "User contract dependency correctly deleted."
	},
	'couldn\'t delete user contract dependency': {
		es: "No ha sido posible eliminar la dependencia del usuario con el contrato.",
		en: "Couldn't delete user contract dependency."
	},
	'Invitation sent': {
		es: "Invitación enviada correctamente.",
		en: "Invitation sent."
	},
	"client": {
		es: "Contrato",
		en: "Client"
	},
	"disabled modules by client": {
		es: "Módulos deshabilitados por contrato",
		en: "Disabled modules by client"
	},
	"- all -": {
		es: "- Todos -",
		en: "- All -"
	},
	"user correctly updated": {
		es: "El usuario se ha actualizado correctamente.",
		en: "User correctly updated."
	},
	"user couldn't be updated": {
		es: "El usuario no se ha actualizado correctamente.",
		en: "User couldn't be updated."
	},
	"workplace updated": {
		es: "El centro de trabajo se ha actualizado correctamente.",
		en: "Workplace updated."
	},
	"workplace not updated": {
		es: "El centro de trabajo no se ha actualizado correctamente.",
		en: "Workplace not updated."
	},
	"signature": {
		es: "Firma",
		en: "Signature"
	},
	"either email or password is wrong": {
		es: "El email o la contraseña son incorrectos.",
		en: "Either email or password is incorrect."
	},
	"couldn't log in with your microsoft account. please, review your data": {
		es: "No se ha podido iniciar sesión utilizando su cuenta Microsoft. Por favor, revise sus datos.",
		en: "Couldn't log in with your microsoft account. Please, review your data."
	},
	"couldn't find user info": {
		es: "Error al buscar la información del usuario.",
		en: "Coulnd't find user info.",
	},
	"couldn't get user info": {
		es: "No se pudo obtener la información del usuario.",
		en: "Coulnd't get user info.",
	},
	"format rules": {
		es: "Reglas de formato",
		en: "Format rules"
	},
	"new rule": {
		es: "Nueva regla",
		en: "New rule"
	},
	"update rule": {
		es: "Actualizar regla",
		en: "Update rule"
	},
	"eq": {
		es: "Igual",
		en: "Equals"
	},
	"neq": {
		es: "Distinto",
		en: "Not equals"
	},
	"contains": {
		es: "Contiene",
		en: "Contains"
	},
	"starts_with": {
		es: "Comienza por",
		en: "Starts with"
	},
	"gt": {
		es: "Mayor que",
		en: "Greater than"
	},
	"gte": {
		es: "Mayor o igual que",
		en: "Greater than or equals"
	},
	"lt": {
		es: "Menor que",
		en: "Less than"
	},
	"lte": {
		es: "Menor o igual que",
		en: "Less than or equals"
	},
	"fixed": {
		en: "Fixed",
		es: "Fijo"
	},
	"referenced": {
		en: "Referenced",
		es: "Referenciado"
	},
	"string": {
		en: "Text",
		es: "Texto"
	},
	"invitation sent": {
		en: "Invitation sent",
		es: "Invitación enviada"
	},
	"conflict - invitation already exists": {
		en: "Conflict - invitation already exists",
		es: "Conflicto - la invitacion ya existe"
	},
	"conflict - user already exists": {
		en: "Conflict - user already exists",
		es: "Conflicto - el usuario ya existe"
	},
	"conflict - idcard already exists": {
		en: "Conflict - ID card already exists",
		es: "Conflicto - el DNI ya existe"
	},
	"internal server error - error checking user by idcard": {
		en: "Internal Server Error - error checking user by IdCard",
		es: "Error interno - error comprobando el DNI del usuario"
	},
	"user succesfully deleted": {
		en: "User succesfully deleted",
		es: "Usuario borrado satisfactoriamente"
	},
	"user not found": {
		en: "User not found",
		es: "Usuario no encontrado"
	},
	"there's been an error": {
		en: "There's been an error",
		es: "Ha ocurrido un error"
	},
	"group": {
		es: "Grupo",
		en: "Group"
	},
	"groups": {
		es: "Grupos",
		en: "Groups"
	},
	"create group": {
		es: "Crear grupo",
		en: "Create group",
	},
	"edit group": {
		es: "Editar grupo",
		en: "Edit group",
	},
	"group detail": {
		en: "Detalle grupo",
		es: "Group detail",
	},
	"limited functionalities": {
		es: "Funcionalidades limitadas",
		en: "Limited functionalities"
	},
	"group correctly created": {
		es: "Grupo creado correctamente",
		en: "Group correctly created"
	},
	"group correctly updated": {
		es: "Grupo actualizado correctamente",
		en: "Group correctly updated"
	},
	"something went wrong on group creation": {
		es: "Ha ocurrido un error durante la creación del grupo",
		en: "Something went wrong on group creation"
	},
	"something went wrong on group update": {
		es: "Ha ocurrido un error durante la actualización del grupo",
		en: "Something went wrong on group update"
	},
	"none": {
		es: "Ninguno",
		en: "None"
	},
	"reader": {
		es: "Lector",
		en: "Reader"
	},
	"editor": {
		en: "Editor",
		es: "Editor"
	},
	"creator": {
		en: "Creator",
		es: "Creador"
	},
	"contributor": {
		en: "Contributor",
		es: "Gerente"
	},
	"manager": {
		en: "Manager",
		es: "Gestor"
	},
	"account linked.": {
		en: "Account linked.",
		es: "Cuenta enlazada."
	},
	"account linked. now you can log in with your email and defined password": {
		en: "Account linked. Now you can log in with your email and defined password",
		es: "Cuenta enlazada. Ya puedes iniciar sesión con el correo electrónico y la contraseña definidos"
	},
	"couldn't link account": {
		en: "Couldn't link account",
		es: "No ha sido posible enlazar la cuenta"
	},
	"wrong email or password. try again": {
		en: "Wrong email or password. Try again",
		es: "Correo electrónico o contraseña inválidos. Inténtelo de nuevo"
	},
	"internal server error": {
		en: "Internal server error",
		es: "Error de servidor"
	},
	"conflict": {
		en: "Conflict",
		es: "Conflicto"
	},
	"idcard already exists": {
		en: "Id Card already exists",
		es: "Ya existe el DNI"
	},
	"error checking user by idcard": {
		en: "Error checking user by Id Card",
		es: "Error comprobando el DNI del usuario"
	},
	"user already exists": {
		en: "User already exists",
		es: "El usuario ya existe"
	},
	"invalid value": {
		es: "El valor es inválido",
		en: "Invalid value"
	},
	"something went wrong": {
		en: "Something went wrong",
		es: "Algo ha ido mal"
	},
	"yes": {
		en: "Yes",
		es: "Sí"
	},
	"no": {
		en: "No",
		es: "No"
	}
};