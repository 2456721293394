import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ItemParamsService {

	private params: any;

	constructor() { }

	/**
	 * Sets the parameters of the item
	 * @param parametros 
	 */
	setParams(parametros: any) {
		this.params = parametros;
	}

	/**
	 * Get the paramaters of the item
	 * @returns 
	 */
	getParams() {
		return this.params;
	}
}
