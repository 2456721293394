import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ItemData } from '../interfaces/items/item-data';
import { Origin } from '../enum/origin.enum';


@Injectable({
	providedIn: 'root'
})
export class ItemsService {

	constructor(
		private http: HttpClient,
	) { }

	/**
	 * Generates an item to be saved in datastore and sends it to the origamee api
	 * @param schemaId Schema of the item
	 * @param contractId Contract the item belongs to
	 * @param data The item itself
	 * @param workspaceId Workspace the item belongs to
	 * @returns a promise of http response 
	 */
	public async createItem(schemaId: number, contractId: number, data: ItemData, workspaceId: string): Promise<any> {
		await this.handleFiles(data);

		data.System.CreatedFrom = Origin.WEB;
		data.System.ModifiedFrom = Origin.WEB;
		data.System.disabled = false;

		const body = { Schema_ID: schemaId, Data: data, Contract_ID: contractId, WS_ID: workspaceId };

		return new Promise((resolve, reject) => {
			this.http.post(`api/saveFormAnswer`, body)
				.subscribe(result => {
					resolve(result);
				}, error => {
					reject(error);
				});
		});
	}

	/**
	 * Prepare the files of the item to be saved into the system herarchy
	 * @param data the item information
	 */
	public async handleFiles(data: any): Promise<any> {
		const recordKeys = Object.keys(data.Records);

		let filesInfo = recordKeys.reduce((all: any, key: any) => {
			let fieldValue = data.Records[key];
			if (this.isFile(fieldValue)) {
				//Clear older files
				fieldValue = fieldValue.filter((f: any) => !!f.isNew);
				let promises = fieldValue.map((file: any, index: any) => {
					file.isNew = false;
					return {
						Id: file.Id,
						Filename: file.name,
						Groupname: key,
						Type: file.type,
						Size: file.size,
					}
				});

				return [...all, ...promises];
			}
			return all;
		}, []);

		data.System.Files = data.System.Files.concat(filesInfo);
	}

	/**
	 * Checks if the value of a formio field is a file
	 * @param fieldValue The formio value
	 * @returns true if is a file
	 */
	private isFile(fieldValue: any): boolean {
		return Array.isArray(fieldValue) && fieldValue.length > 0 && fieldValue.some(f => !!f.isFile);
	}

}
