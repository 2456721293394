<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
</ngx-spinner>
<nb-layout>
	<nb-layout-header fixed>
		<div class="toolbar" role="banner">
			<img alt="Angular Logo" [src]="logo" class="logo" (load)="showImg()"/>
			<div class="spacer"></div>
		</div>
	</nb-layout-header>

	<nb-layout-column class="column-content">

		<div class="content" role="main" *ngIf="!loading">

			<formio id="formio" [form]="formularioConfig" [submission]="formularioSubmission" [refresh]="refreshForm"
				(submit)="onSubmit($event)" (customEvent)="customEvent($event)" (render)="rendered($event)" (change)="change($event)"></formio>

		</div>

		<router-outlet></router-outlet>

	</nb-layout-column>

</nb-layout>