import { LocationStrategy } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { LanguageLocale } from '../enum/language-locale.enum';

import { texts } from './translations';

export enum Language {
	ES = "es",
	EN = "en",
}

@Injectable({
	providedIn: 'root',
})
export class LanguageService {

	private readonly DEFAULT_LANGUAGE: Language = Language.ES;
	private readonly translations = texts;

	private _language: Language = Language.ES;

	constructor(
		private locationStrategy: LocationStrategy,
		@Inject(LOCALE_ID) private _localeId: string,
		private intlService: IntlService
	) { }

	get language(): Language {
		return this._language;
	}

	public translateText(text: string): string {
		const text_lc = text.toLowerCase();

		const traduction: string = this.translations[text_lc] && this.translations[text_lc][this._language];

		return !!traduction ? traduction : this.capitalize(text);
	}

	private capitalize(text: string): string {

		return `${text.charAt(0).toUpperCase()}${text.slice(1)}`
	}
}