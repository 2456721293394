<nb-layout>
	<nb-layout-header fixed>
		<div class="toolbar" role="banner">
			<img width="220" alt="Angular Logo" [src]="logo" />
			<div class="spacer"></div>
		</div>
	</nb-layout-header>

	<nb-layout-column>

		<div class="content" role="main">
			<div class="container">
				<nb-card class="card">
					<nb-card-header class="card-header">Respuesta no enviada</nb-card-header>
					<nb-card-body class="card-body">
						<div class="paragraph">
							<nb-icon [icon]="messages.ErrorMessageIcon ? messages.ErrorMessageIcon : 'close-outline'" class="card-p-icon"></nb-icon>
							<p class="card-p-text">{{messages.ErrorMessage ? messages.ErrorMessage : 'No ha sido posible enviar su respuesta al cuestionario'}}</p>
						</div>
					</nb-card-body>
					<nb-card-footer class="card-footer">
						<div class="footer-paragraph">
							<nb-icon [icon]="messages.ErrorInfoIcon ? messages.ErrorInfoIcon : 'alert-triangle-outline'" class="card-f-icon"></nb-icon>
							<p class="card-f-text">{{messages.ErrorInfo ? messages.ErrorInfo : 'Pruebe de nuevo o contacte con su administrador si el problema persiste.'}}</p>
						</div>
					</nb-card-footer>
				</nb-card>
			</div>
		</div>

	</nb-layout-column>

</nb-layout>