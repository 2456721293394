import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {

	private readonly httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	constructor(private http: HttpClient) { }

	/**
	 * Obtains the configuration from public-schemas with an id
	 * @param id of the public-schema
	 * @returns the configuration
	 */
	public async getConfig(id: string): Promise<any> {

		return new Promise((resolve, reject) => {
			this.http.get(`api/${id}`,
				this.httpOptions)
				.subscribe(result => {
					resolve(result);
				}, error => {
					reject(error);
				});
		});
	}

	/**
	 * Obtains the messages from public-schemas with an id
	 * @param id of the public-schema
	 * @returns the messages
	 */
	public async getMessages(id: string, type: string): Promise<any> {

		return new Promise((resolve, reject) => {
			this.http.get(`api/${type}/${id}`,
				this.httpOptions)
				.subscribe(result => {
					resolve(result);
				}, error => {
					reject(error);
				});
		});
	}
}
